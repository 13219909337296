<template>
    <el-dialog :visible="visible" width="40%" @close="handleClose" class="dialog" :title="title">
        <el-form ref="form" :model="form" label-width="110px" size="mini" :rules="rules">
            <el-form-item label="小屋名称：" prop="healthHutName">
                <el-input v-model="form.healthHutName" />
            </el-form-item>
            <el-form-item label="设备名称：" prop="deviceName">
                <el-input v-model="form.deviceName" />
            </el-form-item>
            <el-form-item label="设备类型：" prop="deviceTypeName">
                <el-input v-model="form.deviceTypeName" />
            </el-form-item>
            <el-form-item label="检查号：" prop="examItemId" v-if="!form.id">
                <el-input v-model="form.examItemId" />
            </el-form-item>
            <el-form-item label="检查时间：" prop="examTime" v-if="!form.id">
                <el-input v-model="form.examTime" />
            </el-form-item>
            <el-form-item label="姓名：" prop="patientName" v-if="!form.id">
                <el-input v-model="form.patientName" />
            </el-form-item>
            <el-form-item label="性别：" prop="gender" v-if="!form.id">
                <el-input v-model="form.gender" />
            </el-form-item>
            <el-form-item label="年龄：" prop="age" v-if="!form.id">
                <el-input v-model="form.age" />
            </el-form-item>
            <el-form-item label="项目：" prop="itemName" v-if="!form.id">
                <el-input v-model="form.itemName" />
            </el-form-item>
            <el-form-item label="结果：" prop="itemValue" v-if="!form.id">
                <el-input v-model="form.itemValue" />
            </el-form-item>
            <el-form-item label="备注：" prop="remark">
                <el-input v-model="form.remark" />
            </el-form-item>
            <el-form-item style="padding-top: 40px;text-align: right">
                <!-- <el-button type="primary" @click="onSubmit">确 定</el-button> -->
                <el-button @click="handleClose">关 闭</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            default: {}
        },
        title: {
            default: "详情"
        },
    },
    data() {
        return {
            innerVisible: false,
            roleList: [],
            rules: {
                username: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                nickname: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                password: [
                    { required: true, message: '请输入原密码', trigger: 'blur' },
                    { min: 5, max: 132, message: '长度在 5 到 32 个字符', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        getData() {
            this.dialogVisible = false
            this.$http.get(`/admin/role?page=1&pageSize=500`)
                .then(resp => {
                    this.roleList = resp.data
                }).catch(err => {
                    this.$message.error(err.message)
                })
        },
        async onSubmit() {
            var va = false
            this.$refs.form.validate((valid) => {
                if (valid) {
                    va = true
                }
            })
            if (!va) {
                return
            }
            try {
                let url = `/auth/user`
                const resp = await this.$http.post(url, this.form)
                this.$message[resp.data.status](resp.data.msg)
            } catch (e) {
                this.$message.error(e.message)
            }
            this.$emit('update')
        },
        handleClose() {
            this.$emit('close')
        },
    }
}
</script>

<style lang="scss" scoped>
.dialog ::v-deep .el-dialog__body {
    padding: 10px 20px;
}

.tijian {
    border: 1px solid #c0c4cc;
    padding: 5px;
}

.title {
    display: flex;
    align-items: center;
    padding: 10px;

    .title-text {
        text-align: center;
        border-bottom: 1px solid #EBEEF5;
        border-top: 1px solid #EBEEF5;
        padding: 10px;
    }
}

::v-deep .el-table .el-table__cell {
    padding: 6px 0;
}

.flex {
    display: flex;
}

.btn {
    justify-content: space-between;
    padding: 10px 0;
}

.border-bottom {
    border-bottom: 1px solid #EBEEF5;
}

.text-center {
    text-align: center;
    padding: 5px 0;
}

.align-items {
    align-items: center;
}

.text-group {
    display: grid;
    padding-right: 10px
}
</style>
